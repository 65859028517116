import React, { useEffect, useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

import DarkMode from "../DarkMode/DarkMode";
import "./darkmode.css";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const threshold = 100;
      setIsScrolled(scrollTop > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 

  const toggleButton = () => {
    setVisibility(!visible);
  };

 

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

//onClick={() => scrollTo("mision")}
//onClick={() => scrollTo("about")}
//onClick={() => scrollTo("service")}

    return (
      <>
      
       
       
      </>
    );
  };

  //        <DarkMode/>

  return (
    <HeaderSection id="header" isScrolled={isScrolled}>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.png" width="" height="70px" />
          </LogoContainer>
          <NotHidden>
           
          </NotHidden>
         
        </Row>
       
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
