import styled, { DefaultTheme } from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")<HeaderProps>`
  padding: 1rem 0.5rem;
  background-color: ${(props) => props.isScrolled && "#F1F1F1"};
  transition: background-color 0.3s ease-in;
  width: 100%;
  z-index:1000;
  position: fixed;
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;


type HeaderProps = {
  isScrolled: boolean;
  theme?: DefaultTheme;
};

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled("div")`
  display: inline-block;
  text-align: center;
  
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;
  
  

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1.2rem;
  color: rgb(64, 63, 65);
  transition: color 0.2s ease-in;
  margin: 0.5rem 2rem;
  

  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: #866493;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;


export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: rgb(133,102,144);
    text-underline-position: under;
    text-decoration: rgb(133,102,144) wavy underline;
  }
`;

Span.defaultProps = {
  className: "span2",
};

